import React, { useEffect, useState } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import { Paper, Box, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import PricingBreakdown from "../PricingBreakdown";
import { useSelector } from "react-redux";
import { useApiContext } from "contexts/ApiProvider";
import { useDispatch } from "react-redux";
import { saveQuote } from "redux/slices/Quote/quoteSlice";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import PricingCombined from "../PricingCombined";
import RecommendedPricing from "../RecommendedPricing";
import AccountDetails from "../AccountDetails/AccountDetailsCardLayout";
import QuoteDetailsHeader from "../QuoteDetailsHeader";
import QuoteInsights from "../QuoteInsights";
import QuoteLaneInsights from "components/pages/quote-insights/QuoteLaneInsights";
import QuoteCustomerInsights from "components/pages/quote-insights/QuoteCustomerInsights";
import MarketPricing from "components/pages/quote-details/MarketPricing";
import MarketPricingV2 from "../../quote-pricing/MarketPricing";

const QuoteDetailsLayout = () => {
  const { hashedId } = useParams();
  const { quoteApi } = useApiContext();
  const dispatch = useDispatch();

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  const smallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    quoteApi.getQuoteDetails(hashedId).then((response) => {
      dispatch(saveQuote(response));
    });
  }, [dispatch, hashedId, quoteApi]);

  return (
    <Paper variant="basePaper" sx={{ pt: 0 }}>
      <Box
        sx={{
          mt: "16px",
          mb: "26px",
          ml: 4,
        }}
      >
        <QuoteDetailsHeader
          hashId={hashedId}
          status={quoteData.status}
        ></QuoteDetailsHeader>
      </Box>
      <Box mt={2} ml={4} mr={4}>
        <Grid container spacing={2}>
          <Grid container direction="column" size={{ xs: 12, sm: 12, md: 5 }}>
            <AccountDetails quote={quoteData} />
            {/* <PricingCombined /> */}
            {/* <PricingBreakdown /> */}
            {/* <RecommendedPricing /> */}

            <PricingBreakdown />

            <RecommendedPricing />
          </Grid>
          <Grid container direction="column" size={{ xs: 12, sm: 12, md: 7 }}>
            <QuoteLaneInsights />
            <QuoteCustomerInsights />

            <MarketPricingV2 quote={quoteData} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default QuoteDetailsLayout;
