import * as React from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "../../../icons/ChevronLeft";
import ChevronRight from "../../../icons/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UserMenu from "./UserMenu";
import Grid from "@mui/material/Grid2";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import RoleTypeNames, {
  OrgAdminRoles,
  SocketAccessRoles,
  TenantAdminRoles,
} from "constants/RoleConstant";
import ViewListIcon from "@mui/icons-material/ViewList";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GroupsIcon from "@mui/icons-material/Groups";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";
import PricingAppBar from "./PricingAppBar";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AuthenticatedLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const userData = useSelector((state: any) => state.userRequestReducer.user);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const hasDrawer = (): boolean => {
    if (location.pathname.startsWith("/settings")) {
      return true;
    }
    return false;
  };

  const isItemSelected = (route: string): boolean => {
    return location.pathname === route;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <PricingAppBar />
      {hasDrawer() ? (
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            position: "relative",
            "& .MuiDrawer-paper": {
              overflow: "visible",
              boxShadow: 2,
            },
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: -15,
              top: "48%",
              backgroundColor: "white",
              ":hover": { backgroundColor: "white" },
              border: 1,
              borderColor: "grey.500",
              zIndex: 100,
            }}
            size="small"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open ? (
              <ChevronLeft fontSize="small" />
            ) : (
              <ChevronRight fontSize="small" />
            )}
          </IconButton>
          <List sx={{ overflow: "hidden" }}>
            <ListItem
              disablePadding
              sx={{ display: "block", mt: 14 }}
            ></ListItem>
            {userData.userRole !== RoleTypeNames.CompanyStore &&
            userData.userRole !== RoleTypeNames.Agent ? (
              <NavLink
                to="/settings/pricing"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{ display: "block", my: 3 }}
                >
                  <ListItemButton
                    selected={isItemSelected("/settings/pricing")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: isItemSelected("/settings/pricing")
                          ? `${theme.palette.info.main}`
                          : "#595A5A",
                      }}
                    >
                      <PriceChangeIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Pricing Parameters"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ) : null}
            {}
            {OrgAdminRoles.includes(userData.userRole) ? (
              <NavLink
                to="/settings/user_management"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{ display: "block", my: 3 }}
                >
                  <ListItemButton
                    selected={isItemSelected("/settings/user_management")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: isItemSelected("/settings/user_management")
                          ? `${theme.palette.info.main}`
                          : "#595A5A",
                      }}
                    >
                      <GroupsIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={"User Management"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ) : null}
            {OrgAdminRoles.includes(userData.userRole) ? (
              <NavLink
                to="/settings/office_management"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{ display: "block", my: 3 }}
                >
                  <ListItemButton
                    selected={isItemSelected("/settings/office_management")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: isItemSelected("/settings/office_management")
                          ? `${theme.palette.info.main}`
                          : "#595A5A",
                      }}
                    >
                      <ApartmentIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Office Management"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ) : null}
            {OrgAdminRoles.includes(userData.userRole) ? (
              <NavLink
                to="/settings/parameters"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{ display: "block", my: 3 }}
                >
                  <ListItemButton
                    selected={isItemSelected("/settings/parameters")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: isItemSelected("/settings/parameters")
                          ? `${theme.palette.info.main}`
                          : "#595A5A",
                      }}
                    >
                      <SettingsIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Settings"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ) : null}
            {SocketAccessRoles.includes(userData.userRole) ? (
              <Link
                to={`${process.env.REACT_APP_RULES_ENGINE_BASE_URL}/rules`}
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{ display: "block", my: 3 }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#595A5A",
                      }}
                    >
                      <img
                        src={require("../../../assets/SocketLogo.png")}
                        width={35}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Rules Engine"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ) : null}
            {TenantAdminRoles.includes(userData.userRole) ? (
              <Link
                to="/settings/tenants"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{ display: "block", my: 3 }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#595A5A",
                      }}
                    >
                      <img
                        alt="Metafora"
                        src={require("../../../assets/MetaforaLogo.png")}
                        width={35}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Tenants"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ) : null}
          </List>
          <Divider />
        </Drawer>
      ) : null}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <DrawerHeader />
          </Grid>
          <Grid size={{ xs: 12 }} sx={{ height: 40 }}></Grid>
          <Grid container size={{ xs: 12 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AuthenticatedLayout;
