import React from 'react'
import { Box, Divider, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2";
import StatusChip from 'components/global/common/StatusChip/StatusChip';
import ShareOutlined from 'icons/ShareOutlined';
import { QuoteDetailsHeaderProps } from './Interfaces/interfaces';

const QuoteDetailsHeader: React.FC<QuoteDetailsHeaderProps> = ({ hashId, status }) => {
  return (
    <Box mt={2}>
        <Grid container spacing={1} direction="row" sx={{ alignItems: "center" }}>
            <Typography variant="headerTitle">
                {hashId}
            </Typography>
            <StatusChip status={status} />
            <Divider orientation="vertical" variant="middle" flexItem />
            <ShareOutlined color="secondary" />
        </Grid>
    </Box>
  )
}

export default QuoteDetailsHeader