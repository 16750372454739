import React from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import { Paper, Box, Typography, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import { useTheme } from "@emotion/react";
import ThemeColors from "components/global/theme/theme_colors";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import GreenScreensLogo from "icons/GreenScreensLogo";
import ArrowUpIcon from "icons/svgs/ArrrowUpIcon";
import ArrowDownIcon from "icons/svgs/ArrrowDownIcon";
import FuelIcon from "icons/svgs/FuelIcon";
import MileageIcon from "icons/svgs/MileageIcon";

import { RingProgress, SemiCircleProgress, Text } from "@mantine/core";
import { green } from "@mui/material/colors";
import RatePerMileIcon from "icons/svgs/RatePerMileIcon";
import MarginPercentIcon from "icons/svgs/MarginPercentIcon";
import MarginAmountIcon from "icons/svgs/MarginAmountIcon";
import {
  confidenceColor,
  confidenceColorBg,
} from "utilities/functions/ConfidenceLevelColor";

const RecommendedPricing = () => {
  const { hashedId } = useParams();
  const theme = useTheme();

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: "8px",
        border: "1px solid var(--Border-Primary, #E4E5E6)",
      }}
    >
      <Box sx={{ m: 2 }}>
        <Typography variant="cardTitle" sx={{ mt: 1, mb: 1 }} component="div">
          Recommended Pricing
        </Typography>
        <Box></Box>
        <Paper
          variant="outlined"
          sx={{ bgcolor: ThemeColors.paperBackground, borderRadius: "8px" }}
        >
          <Grid container sx={{ py: "20px" }}>
            <Grid size={{ md: 6 }} container sx={{ pl: 1 }}>
              <Grid size={{ md: 12 }} sx={{ mx: "5px", height: "36px" }}>
                <GreenScreensLogo />
              </Grid>
              {/* Expected Buy Rate */}
              <Grid
                size={{ md: 6 }}
                sx={{ px: "8px" }}
                container
                flexDirection="column"
              >
                <Typography variant="cardTextLargeHeader">
                  Expected Buy Rate
                </Typography>
                <Typography
                  sx={{
                    color: "#302E2C",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  {formatCurrency(
                    quoteData.projectedCost?.companyRate?.targetBuyRate
                  )}
                </Typography>
              </Grid>
              {/* Confidence */}
              <Grid
                size={{ md: 6 }}
                sx={{ px: "8px", alignItems: "center" }}
                container
                flexDirection="column"
              >
                <Typography variant="cardTextLargeHeader">
                  Confidence
                </Typography>
                <SemiCircleProgress
                  fillDirection="left-to-right"
                  orientation="up"
                  filledSegmentColor={confidenceColor(
                    quoteData.projectedCost?.companyRate?.confidenceLevel ?? 0
                  )}
                  emptySegmentColor={confidenceColorBg(
                    quoteData.projectedCost?.companyRate?.confidenceLevel ?? 0
                  )}
                  size={80}
                  thickness={14}
                  value={
                    quoteData.projectedCost?.companyRate?.confidenceLevel ?? 0
                  }
                  label={
                    quoteData.projectedCost?.companyRate?.confidenceLevel ?? "-"
                  }
                  styles={{
                    label: {
                      color: "#595856",
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Poppins",
                      bottom: "-5px",
                    },
                  }}
                />
              </Grid>

              {/* High */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <ArrowUpIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">High</Typography>
                  <Typography variant="cardText">
                    {formatCurrency(
                      quoteData.projectedCost?.companyRate?.highBuyRate
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* Low */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <ArrowDownIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">Low</Typography>
                  <Typography variant="cardText">
                    {formatCurrency(
                      quoteData.projectedCost?.companyRate?.lowBuyRate
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* Fuel */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <FuelIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">Fuel</Typography>
                  <Typography variant="cardText">
                    {formatCurrency(
                      quoteData.projectedCost?.companyRate?.fuelRate
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* Mileage */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <MileageIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">Mileage</Typography>
                  <Typography variant="cardText">
                    {quoteData.projectedCost?.companyRate?.mileage?.toFixed(0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginRight: "-1px" }}
            />
            <Grid size={{ md: 6 }} container sx={{ pl: 1 }}>
              <Grid size={{ md: 12 }} sx={{ mx: "5px", height: "36px" }}></Grid>
              {/* Target Sell Rate */}
              <Grid
                size={{ md: 12 }}
                sx={{ px: "8px" }}
                container
                flexDirection="column"
              >
                <Typography variant="cardTextLargeHeader">
                  Target Sell Rate
                </Typography>
                <Typography
                  sx={{
                    color: "#302E2C",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  {formatCurrency(
                    quoteData.quoteTargetSellRateDto?.targetSellRate
                  )}
                </Typography>
              </Grid>

              {/* $ Margin */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <MarginAmountIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">$ Margin</Typography>
                  <Typography variant="cardText">
                    {formatCurrency(
                      quoteData.quoteTargetSellRateDto?.totalMarginAmount
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* % Margin */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <MarginPercentIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">% Margin</Typography>
                  <Typography variant="cardText">
                    {`${quoteData.quoteTargetSellRateDto?.totalMarginPercent?.toFixed(
                      2
                    )}%`}
                  </Typography>
                </Grid>
              </Grid>

              {/* RPM */}
              <Grid
                size={{ md: 6 }}
                container
                sx={{
                  padding: " 7px 10px",
                  alignItems: "center",
                }}
              >
                <RatePerMileIcon
                  sx={{
                    color: ThemeColors.metaforaGreen,
                    width: "24px",
                    height: "24px",
                    mr: "6px",
                  }}
                />
                <Grid container flexDirection="column">
                  <Typography variant="cardTextHeader">RPM</Typography>
                  <Typography variant="cardText">
                    {formatCurrency(quoteData.quoteTargetSellRateDto?.totalRpm)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Paper>
  );
};

export default RecommendedPricing;
