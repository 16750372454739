import { SvgIcon } from "@mui/material";

const RatePerMileIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M18.1002 5.3C18.0002 4.8 17.6002 4.5 17.1002 4.5H13.0002L13.2002 7.5H10.8002L11.0002 4.5H6.80021C6.30021 4.5 5.90021 4.9 5.80021 5.3L3.10021 19.3C3.00021 19.9 3.50021 20.5 4.10021 20.5H10.0002L10.3002 15.5H13.7002L14.0002 20.5H19.8002C20.4002 20.5 20.9002 19.9 20.8002 19.3L18.1002 5.3ZM10.4002 13.5L10.6002 9.5H13.2002L13.4002 13.5H10.4002Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default RatePerMileIcon;
