import { Navigate, Route, Routes } from "react-router-dom";
import QuoteHistory from "./quote-history/QuoteHistory";
import RequestQuote from "./request-quote/RequestQuote";
import PricingParameters from "./pricing-parameters/PricingParameters";
import PageNotFound from "./404/PageNotFound";
import QuoteDetails from "./quote-details/QuoteDetails";
import UserManagement from "./user-management/UserManagement";
import OfficeManagement from "./office-management/OfficeManagement";
import AddNewOffice from "./add-new-office/AddNewOffice";
import PermissionDenied from "components/pages/permission-denied/PermissionDenied";
import { useSelector } from "react-redux";
import RoleTypeNames, {
  OrgAdminRoles,
  TenantAdminRoles,
} from "constants/RoleConstant";
import BatchQuote from "./request-quote/batch-quote/BatchQuote";
import APIErrorLogHistory from "./api-errorlog/APIErrorLogHistory";
import { LoginCallback } from "@okta/okta-react";
import LoginPage from "./routes/Login";
import { RequireAuthentication } from "./routes/RequireAuthentication";
import Loading from "./routes/Loading";
import { RequireAuthorization } from "./routes/RequireAuthorization";
import { useUserContext } from "contexts/UserProvider";
import TenantManagement from "./tenants/TenantManagement";
import SettingsManagement from "./settings/SettingsManagement";
import PublicQuote from "./quote-public/PublicQuote";
import AuthenticatedLayout from "components/global/layout/AuthenticatedLayout";
import PublicLayout from "components/global/layout/PublicLayout";
import QuoteDetailsLayout from "./quote-details-2/Skeleton/QuoteDetailsLayout";
import { useFlags } from "launchdarkly-react-client-sdk";

const AppRoutes = () => {
  const userData = useSelector((state: any) => state.userRequestReducer.user);
  const { currentUser } = useUserContext();

  const ldFlags = useFlags();

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loading />} />}
      />
      <Route element={<PublicLayout />}>
        <Route path="quote-details/:hashedId" element={<PublicQuote />} />
      </Route>

      <Route element={<AuthenticatedLayout />}>
        <Route path="" element={<RequireAuthentication />}>
          <Route path="quote/batchquote" element={<BatchQuote />} />
          <Route path="quote/history" element={<QuoteHistory />} />
          <Route path="api/errorlog" element={<APIErrorLogHistory />} />
          <Route path="quote/request" element={<RequestQuote />} />
          <Route
            path="quote/:hashedId"
            element={
              ldFlags["quote-details-new-design"] ? (
                <QuoteDetailsLayout />
              ) : (
                <QuoteDetails />
              )
            }
          />
          <Route path="quote-old/:hashedId" element={<QuoteDetails />} />
          <Route
            path="quote-refactor/:hashedId"
            element={<QuoteDetailsLayout />}
          />
          <Route
            path="settings/pricing"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={[
                  RoleTypeNames.SystemAdmin,
                  RoleTypeNames.OrgAdmin,
                  RoleTypeNames.Pricing,
                ]}
              >
                <PricingParameters />
              </RequireAuthorization>
            }
          />
          <Route
            path="settings/user_management"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={[
                  RoleTypeNames.SystemAdmin,
                  RoleTypeNames.OrgAdmin,
                ]}
              >
                <UserManagement />
              </RequireAuthorization>
            }
          />
          <Route
            path="settings/office_management"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={OrgAdminRoles}
              >
                <OfficeManagement />
              </RequireAuthorization>
            }
          />

          <Route
            path="settings/add_office"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={[
                  RoleTypeNames.SystemAdmin,
                  RoleTypeNames.OrgAdmin,
                ]}
              >
                <AddNewOffice />
              </RequireAuthorization>
            }
          />
          <Route
            path="edit_office/:id"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={[
                  RoleTypeNames.SystemAdmin,
                  RoleTypeNames.OrgAdmin,
                ]}
              >
                <AddNewOffice />
              </RequireAuthorization>
            }
          />
          <Route
            path="settings/parameters"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={OrgAdminRoles}
              >
                <SettingsManagement />
              </RequireAuthorization>
            }
          />
          <Route
            path="settings/tenants"
            element={
              <RequireAuthorization
                user={currentUser}
                authorizedRoles={TenantAdminRoles}
              >
                <TenantManagement />
              </RequireAuthorization>
            }
          />
        </Route>

        <Route path="/404" element={<PageNotFound />} />
        <Route path="/permission-denied" element={<PermissionDenied />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
