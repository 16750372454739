import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { isNumber } from "@okta/okta-auth-js";
import {
  ImpersonationDto,
  ListItemDto,
  TenantDto,
  UserDto,
} from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";
import {
  Component,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveUser } from "redux/slices/User/userSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TenantImpersonationDialog = (props: {
  dialogOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { dialogOpen, setOpen } = props;
  const { impersonationApi } = useApiContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [roles, setRoles] = useState<ListItemDto[]>([]);
  const [tenants, setTenants] = useState<TenantDto[]>([]);

  const userData = useSelector<any, UserDto>(
    (state: any) => state.userRequestReducer.user
  );
  const [selectedTenant, setSelectedTenant] = useState<string>(
    userData.tenant?.id?.toString() ?? ""
  );
  const [selectedRole, setSelectedRole] = useState<string>(
    userData.userRoleId?.toString() ?? ""
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userData.tenant?.id) {
      setSelectedTenant(userData.tenant?.id?.toString());
    }
    if (userData.userRoleId) {
      setSelectedRole(userData.userRoleId?.toString());
    }
  }, [userData]);

  useEffect(() => {
    impersonationApi.getImpersonationOptions().then((results) => {
      setRoles(results.roles ?? []);
      setTenants(results.tenants ?? []);
    });
  }, [impersonationApi]);

  const handleSave = async () => {
    const request = new ImpersonationDto({
      impersonatedTenantId: selectedTenant
        ? Number(selectedTenant)
        : userData.tenant?.id,
      impersonatedRoleId: selectedRole
        ? Number(selectedRole)
        : userData.userRoleId,
    });

    console.log("Impersonation request: ", request);
    await impersonationApi.impersonate(request).then((response) => {
      console.log("Impersonated: ", response);
      // dispatch(saveUser());
      setOpen(false);
      navigate("/quote/history");
    });
  };

  return (
    <BootstrapDialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle>Impersonate Tenant & Role</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <Typography>Current Tenant: {userData.tenant?.name}</Typography>
          </Grid>
          <FormControl sx={{ m: 1 }} fullWidth>
            <InputLabel id="tenant-select-label">Override Tenant</InputLabel>
            <Select
              labelId="tenant-select-label"
              id="tenant-select"
              value={selectedTenant}
              label="Override Tenant"
              onChange={(e) => setSelectedTenant(e.target.value)}
            >
              {tenants.map((t) => (
                <MenuItem key={`tenant_${t.id}`} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid item>
            <Typography>Current Tenant: {userData.userRole}</Typography>
          </Grid>
          <FormControl sx={{ m: 1 }} fullWidth>
            <InputLabel id="role-select-label">Override Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              value={selectedRole}
              label="Override Role"
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles.map((t) => (
                <MenuItem key={`role_${t.value}`} value={t.value}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" autoFocus onClick={handleSave}>
          Update
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
export default TenantImpersonationDialog;
