import { Grid, Paper, Typography } from "@mui/material";
import QuoteHistoryGrid from "./QuoteHistoryGrid";
import {
  FileResponse,
  QuoteHistoryDto,
} from "../../../api/PricingPlatformApi.generated";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useApiContext } from "contexts/ApiProvider";

const QuoteHistory = () => {
  const navigate = useNavigate();

  const [quoteHistory, setQuoteHistory] = useState<QuoteHistoryDto[] | null>(
    []
  );
  //let selectedQuotes : string[] = [];
  const [selectedQuotes, setSelectedQuotes] = useState<string[] | any>();
  const [isExportButtonEnabled, setIsExportButtonEnabled] = useState(false);

  const { requestQuoteApi } = useApiContext();

  const handleRowSelection = (selectedRows: string[]) => {
    // Check if any rows are selected
    setIsExportButtonEnabled(Object.keys(selectedRows).length > 0);
    setSelectedQuotes(selectedRows);
  };

  const ExportQuotes = async (hashedIds: string[] | any) => {
    try {
      const response: FileResponse =
        await requestQuoteApi.exportQuotes(hashedIds);
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const fileName = response.fileName;
        saveAs(blob, fileName);
      }
    } catch (error) {
      console.error("Error exporting Excel file", error);
    }
  };

  const handleExportQuotes = () => {
    ExportQuotes(selectedQuotes);
  };

  useEffect(() => {
    const getQuoteHitory = async () => {
      try {
        const data = await requestQuoteApi.getQuoteHistory();
        setQuoteHistory(data);
      } catch (error) {
        console.error("Error retreieving quoteHistory", error);
      }
    };
    getQuoteHitory();
  }, []);

  return (
    <Grid item xs={12} width="100%">
      <Paper variant="basePaper">
        <Grid container>
          <Grid container xs={12} item mb={2}>
            <Grid item xs={6}>
              <Typography variant="h4">Quote History</Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent="flex-end"
              alignItems="end"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/quote/batchquote")}
                style={{ marginRight: "16px" }}
              >
                BATCH QUOTE
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/quote/request")}
                style={{ marginRight: "16px" }}
              >
                Create Quote
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/api/errorlog")}
              >
                API Error Log
              </Button>
            </Grid>
          </Grid>
          <Grid item mb={2} container justifyContent="flex-end">
            <Button
              color="primary"
              sx={{ marginLeft: "4px" }}
              variant="contained"
              disabled={!isExportButtonEnabled}
              onClick={handleExportQuotes}
            >
              Export Quotes
            </Button>
          </Grid>
          <Grid item container xs={12}>
            {quoteHistory ? (
              <QuoteHistoryGrid
                data={quoteHistory}
                onRowSelection={handleRowSelection}
              />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default QuoteHistory;
