import { Box, Paper, Typography } from "@mui/material";
import { AccountDetailsProps } from "../Interfaces/interfaces";
import Grid from "@mui/material/Grid2";
import ThemeColors from "components/global/theme/theme_colors";
import {
  AccountBoxOutlined,
  LocalShippingOutlined,
  AdjustOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import { dayMMDDYYYY } from "utilities/time-formatting/moment-util";

const AccountDetailsCardInformation: React.FC<AccountDetailsProps> = ({
  quote,
}) => {
  console.log(quote);

  const getStop = (location: string) => {
    if (location === "dest") {
      return quote?.stops
        ? `${quote.stops[quote?.stops?.length - 1]?.city}, 
                ${quote.stops[quote?.stops?.length - 1]?.state} 
                ${quote.stops[quote?.stops?.length - 1]?.zipCode}`
        : "";
    }

    return quote?.stops
      ? `${quote.stops[0]?.city}, ${quote.stops[0]?.state} ${quote.stops[0]?.zipCode}`
      : "";
  };
  return (
    <Box mb={2}>
      <Paper
        square={true}
        variant="outlined"
        sx={{
          backgroundColor: ThemeColors.paperBackground,
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          sx={{ justifyContent: "space-around" }}
          mt={1}
          mb={1}
          spacing={2}
        >
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              sx={{ alignItems: "center" }}
              spacing={0.5}
            >
              <AccountBoxOutlined color="secondary" />
              <Grid container direction="column">
                <Typography variant="accountCardInfoTitle">Account</Typography>
                <Typography variant="caption">
                  {quote?.account?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ alignItems: "center" }}
              spacing={0.5}
            >
              <LocalShippingOutlined color="secondary" />
              <Grid container direction="column">
                <Typography variant="accountCardInfoTitle">
                  Equipment
                </Typography>
                <Typography variant="caption">
                  {quote?.equipmentType?.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              sx={{ alignItems: "center" }}
              spacing={0.5}
            >
              <AdjustOutlined color="secondary" />
              <Grid container direction="column">
                <Typography variant="accountCardInfoTitle">
                  {getStop("origin")}
                </Typography>
                <Typography variant="caption">
                  {dayMMDDYYYY(quote?.pickupDate)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ alignItems: "center" }}
              spacing={0.5}
            >
              <LocationOnOutlined color="secondary" />
              <Grid container direction="column">
                <Typography variant="accountCardInfoTitle">
                  {getStop("dest")}
                </Typography>
                <Typography variant="caption">
                  estimate delivery Placeholder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AccountDetailsCardInformation;
