import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import pricingParameterReducer from "./slices/PricingParameters/pricingParametersSlice";
import pricingOverrideReducer from "./slices/PricingOverride/pricingOverrideSlice";
import quoteRequestReducer from "./slices/Quote/quoteSlice";
import sharedReducer from "./slices/Shared/sharedSlice";
import userReducer from "./slices/User/userSlice";

export const store = configureStore({
    reducer: {
        pricingOverrideReducer: pricingOverrideReducer,
        pricingParametersReducer: pricingParameterReducer,
        quoteRequestReducer: quoteRequestReducer,
        sharedReducer: sharedReducer,
        userRequestReducer: userReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch